.App {
  text-align: center;
}



.main-screen {
  position: static;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 175px;
  width: 65%;
  min-height: auto;
}




.blank {
  width: 100%;
  height: 80vh;
}



.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-out;
}


/*MEDIUM SCEEEN MEDIA QUARIES*/



@media screen and (max-width: 1280px) {

.main-screen {
  
  width: 80%;
}

}



@media screen and (max-width: 840px) {

  .main-screen {

    width: 90%;

  }
  
}

/*SMALL SCEEEN MEDIA QUARIES*/

@media screen and (max-width: 480px) {

  .main-screen {

    width: 90%;
    margin-top: 120px;
    min-height: auto;

    
    
    

  }
  
}

@media screen and (max-width: 280px) {

  .App {
  display: none;}



}



