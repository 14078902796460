.contact-button {
    border: solid 1px rgb(0, 0, 0, 0.4);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 50px;
    height: 85%;
    background-color: rgba(255, 255, 255, 1);
    color: rgb(0, 0, 0) ;
    font-weight: 600;
    transition: 0.2s;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: 0.3s;
}

.contact-button-frozen {
    pointer-events: none;
    opacity: 0.25;
    border: solid 1px rgb(0, 0, 0, 0.4);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    font-size: 18px;
    margin-right: 50px;
    height: 85%;
    background-color: rgba(255, 255, 255, 1);
    color: rgb(0, 0, 0) ;
    font-weight: 600;
    transition: 0.2s;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: 0.3s;
}



Link {
    align-self:center;
    padding-top: 10px;
}

.contact-button:hover {
    cursor: pointer;
    border: solid 1px rgb(255, 255, 255);
    border-radius: 4px;

    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255) ;
    font-weight: 600;


    
}

@media screen and (max-width: 840px) {

    .contact-button {
        font-size: 16px;
        border: solid 1px rgb(0, 0, 0, 1);
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 4px;
        margin-right: 25px;
        height: 85%;
        background-color: rgba(255, 255, 255, 1);
        color: rgb(0, 0, 0) ;
        font-weight: 600;
        transition: 0.2s;
        padding-top: 6px;
        padding-bottom: 10px;
        transition: 0.3s;
    }
    
    .contact-button-frozen {
        font-size: 16px;
        pointer-events: none;
        opacity: 0.1;
        border: solid 1px rgb(0, 0, 0, 1);
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 4px;
        margin-right: 25px;
        height: 85%;
        background-color: rgba(255, 255, 255, 1);
        color: rgb(0, 0, 0) ;
        font-weight: 600;
        transition: 0.2s;
        padding-top: 6px;
        padding-bottom: 10px;
        transition: 0.3s;
    }

    
    
    
}



@media screen and (max-width: 480px) {

    .contact-button {
        border: solid 1px rgb(0, 0, 0, 1);
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 4px;
        font-size: 15px;
        margin-right: 25px;
        height: 75%;
        background-color: rgba(255, 255, 255, 1);
        color: rgb(0, 0, 0) ;
        font-weight: 600;
        transition: 0.2s;
        padding-top: 5px;
        padding-bottom: 10px;
        transition: 0.3s;
    }
    
    .contact-button-frozen {
        pointer-events: none;
        opacity: 0.25;
        border: solid 1px rgb(0, 0, 0, 1);
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 4px;
        font-size: 15px;
        margin-right: 25px;
        height: 75%;
        background-color: rgba(255, 255, 255, 1);
        color: rgb(0, 0, 0) ;
        font-weight: 600;
        transition: 0.2s;
        padding-top: 5px;
        padding-bottom: 10px;
        transition: 0.3s;
    }

    
    
    
}