.bar {
    background-color: rgb(0, 0, 0);
    border-radius: 2px;
    height: 4px;
    width: 35px;

}

.bar_2{
    background-color: rgb(0, 0, 0);
    border-radius: 2px;
    height: 4px;
    width: 40px;
}


#upper {
    margin-bottom: 8px;
    transition: 0.2s;
}

#upper_2 {
    position: relative;
    top: 4px;
    transform: rotate(45deg);
    transition: 0.1s;
}



#middle {
    width: 25px;
    transition: 0.2s;
}

#middle_2 {
    display: none;
    width: 0;
    transition: 0.2s;
}

#lower {
    margin-top: 8px;
    transition: 0.2s;
}




#lower_2 {
    position: relative;
    top: 0px;
    transform: rotate(-45deg);
    transition: 0.1s;
}



.sandwich-menu:hover {
    cursor: pointer;
}

.sandwich-menu:hover #middle{
    width: 10px;
}


@media screen and (max-width: 480px) {


    .bar {
        background-color: rgb(0, 0, 0);
        border-radius: 2px;
        height: 3px;
        width: 25px;
    
    }
    
    .bar_2{
        background-color: rgb(0, 0, 0);
        border-radius: 2px;
        height: 3px;
        width: 25px;
    }
    
    
    #upper {
        margin-bottom: 5px;
        transition: 0.2s;
    }
    
    #upper_2 {
        position: relative;
        top: 2.5px;
        transform: rotate(45deg);
        transition: 0.1s;
    }
    
    
    
    #middle {
        width: 25px;
        transition: 0.2s;
    }
    
    #middle_2 {
        display: none;
        width: 0;
        transition: 0.2s;
    }
    
    #lower {
        margin-top: 5px;
        transition: 0.2s;
    }
    
    
    
    
    #lower_2 {
        position: relative;
        top: 0px;
        transform: rotate(-45deg);
        transition: 0.1s;
    }
    
    
    
    .sandwich-menu:hover {
        cursor: pointer;
    }
    
    .sandwich-menu:hover #middle{
        width: 10px;
    }
    



}


