.anim {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(180deg, #ef450d, #002de3);
       animation: animate 10s linear infinite;
     }
     @keyframes animate {
       0% {
         filter: hue-rotate(0deg);
       }
 
       50% {
         filter: hue-rotate(35deg);
       }
 
       100% {
         filter: hue-rotate(0deg);
  }
}

