.email-text {
    color: black;
    margin-top: 0;
    font-size: 3.5vw;
    }
    
.email-text:hover {
    cursor: pointer;
    color: rgb(255, 64, 0);
}

.copy-message {
    font-size: 1vw;
    font-weight: 500;
    text-align: left;
    margin: 0;
   
}

@media screen and (max-width: 1280px) {

    .copy-message {
        font-size: 1.5vw;
        font-weight: 500;
        text-align: left;
        margin: 0;
       
    }

}




@media screen and (max-width: 840px) {

    .email-text {

        font-size: 5vw;
        
        }
        
   
    }
    




@media screen and (max-width: 480px) {

    .email-text {
        color: black;
        margin-top: 0;
        font-size: 5.5vw;
        font-weight: 800px;
        }
        
    .email-text:hover {
        cursor: pointer;
        color: rgb(255, 64, 0);
    }
    
    .copy-message {
        font-size: 4vw;
        font-weight: 500;
        text-align: left;
        margin: 0;
       
    }
    
}


@media screen and (max-width: 390px) {

    .email-text {
   
        font-size: 5.5vw
    }

    .copy-message {
        font-size: 3.5vw;
        font-weight: 500;
        text-align: left;
        margin: 0;
       
    }
        

    
}

