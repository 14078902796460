
.footer {
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    height: 800px;
    margin-top: 20vh;
    flex-direction: column;
    justify-content: space-around;
    display: flex;
    padding-top: 5%;
    padding-bottom: 5%;
}

h2 {
    color: white;
    font-size: 36px;
    

}



.footer-elements-wrap {
    width: 65%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: right;
    
}

#logo {

    margin: 0;
}

.footer-section {
    display: flex;
  
}

img {
    width: 100%;
    height: 100%;
}

.full-logo-wrap {

    display:flex;

    
}

.full-logo {
    width: 100%;
}


#social-media, #email {
    justify-content: right;
    padding-bottom: 20px;
    
}



#footer-menu {
    flex-direction: row;
    justify-content: left;
    padding-bottom: 0;
}

.footer-menu-link {
    color: rgb(0, 0, 0);
    margin-right: 20px;
    transition: 0.2s;
    font-weight: 400;
}

.footer-menu-link:hover{
    cursor: pointer;
    text-decoration: underline;
}

#ML {
    justify-content: right;
    margin-bottom: 20px;
}

#copyright{
  
    margin-top: 20px;
    text-align: left;
}



.copyright-text {
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: 400;
}




.main {
    position: static;
    width: 65%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 175px;
  
    min-height: 100vh;
  }



/*MEDIUM SCREEN MEDIA QUARIES*/


@media screen and (max-width: 1280px) {

    .footer-elements-wrap {
        width: 80%;
     
      }

      #social-media, #email {
        justify-content: right;
        padding-bottom: 20px;
        
    }

}

@media screen and (max-width: 884px) {

    .footer {

        width: 100%;
        height: 600px;
    }


    .footer-elements-wrap {
      width: 90%;
   
    }

    #social-media, #email {
        justify-content: right;
        padding-bottom: 0px;
        
    }

    
    
  
  }


  @media screen and (max-width: 480px) {

    .footer {

        width: 100%;
        height: 500px;
    }


    .footer-elements-wrap {
      width: 90%;
   
    }

    #social-media, #email {
        justify-content: right;
        padding-bottom: 0px;
        
    }
    
   #logo {
    margin-bottom: 5%;
    margin-top: 5%;
   }
        
    
    
  
  }

  @media screen and (max-width: 390px) {

    .footer {

        width: 100%;
        height: 500px;
    }


    .footer-elements-wrap {
      width: 90%;
   
    }

    #social-media, #email {
        justify-content: right;
        padding-bottom: 0px;
        
    }
    
   #logo {
    margin-bottom: 5%;
    margin-top: 5%;
   }

   .copyright-text {
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: 400;
}

        
    
    
  
  }
  
  
  