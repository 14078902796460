

.main-menu-window{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:  rgb(250, 245, 234, 0.9);
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: right;
    transform: translateY(-120%);
    transition: transform 0.3s ease-in-out;
    z-index: 10;

    display: flex;

}

.main-menu-window.open {

    background-color: rgb(250, 245, 234, 0.9);
    transform: translateY(0%);
    transition: transform 0.3s ease-out;
}


.main-menu-window-elements-wrap {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    width: 65%;
    margin: 125px auto 50px auto;
    color: rgb(0, 0, 0);
 
}

.main-menu-footer {
  
}

.main-menu-window-section {
    display: flex;
    width: 100%;
    justify-content: left;


}


#close-button {
    justify-content: right;
    margin-bottom: 50px;
    filter: invert();
}

.close-icon {
    width: 3%;
    transition: 0.2s;
}

.close-icon:hover {
    cursor: pointer;
    transform: scale(1.1);
}

img {
    width: 100%;
}

#menu-links {
    flex-direction: column;
    align-content: right;
    text-align: right;
    margin-bottom: 100px;

}

#newsletter {
    padding-top: 5%;
    display: flex;
    justify-content: end;
    margin: 0;
}

a, Link, .main-menu-link{
    color: black;
    transition: 0.2s;
    font-weight: 400;
    font-size: 24px;
    text-decoration: none;
    margin-bottom: 25px;
   
}

a:hover {
    cursor: pointer;
    font-weight: 800;

}


.main-menu-full-logo {
    width: 50%;

}

#full-logo {
    justify-content: right;
    margin: 0;
}


#main-menu-window-email {
    margin-top: 4%;
    text-align: left;
    justify-content: right;

}

.main-menu-window-email-text{
    color: #000000;
}

#social-media {
    justify-content: right;
    filter: invert();
}


@media screen and (max-width: 840px) {
    
    .main-menu-window-elements-wrap {
   
        width: 80%;
        margin: 125px auto 50px auto;
        color: rgb(0, 0, 0);

    }
    .main-menu-full-logo {
        width: 75%;
    }
    
  
  }



/*SMALL SCEEEN MEDIA QUARIES*/




  @media screen and (max-width: 480px) {

    
    .main-menu-window-elements-wrap {
        width: 90%;
     
    }

    .main-menu-full-logo {
        width: 90%;
    }

    .main-menu-window-elements-wrap {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        width: 90%;
        margin: 90px auto 50px auto;
        color: rgb(0, 0, 0);
     
    }

    #menu-links {
        flex-direction: column;
        align-content: right;
        text-align: right;
        margin-bottom: 20px;
    
    }

    
    
    
  


    }

    




  @media screen and (max-width: 350px) {

    .main-menu-window{

        transform: translateY(-150%);
  
    
    }

    

    .main-menu-window-elements-wrap {
        width: 90%;
     
    }

    .main-menu-full-logo {
        width: 90%;
    }

    #newsletter {
        display: none;
    }

    .main-menu-window-elements-wrap {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        width: 90%;
        margin: 90px auto;
        color: rgb(0, 0, 0);
     
    }

    #menu-links {
        flex-direction: column;
        align-content: right;
        text-align: right;
        margin-bottom: 20px;
    
    }
    
    
  }
  


  @media screen and (max-height: 320px) {
    
    .main-menu-window-elements-wrap {
        display: none;
    }
  }

