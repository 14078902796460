.about {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-top: 8%;
}

img {
    width: 100%;
}

.about-logo-wrap {
    display: flex;
}

.about-logo {
    width: 100%;
    filter: invert();
    mix-blend-mode:difference;
    animation: animate 10s linear infinite;
  }
  @keyframes animate {
    0% {
     transform: scale(1);
    }

    25% {
      transform:  scaleY(1.05);}

    50% {
        transform:  scaleX(1.01) scaleY(1);}
   
    75% {
        transform:  scaleY(1.05) scaleX(1) ;}


    100% {
      transform: scale(1);}
}



    
.about-text-wrap {
    margin-top: 2%;
}

.about-text {
    color: white;
    text-align: left;
}



@media screen and (max-width: 840px) {
    .about {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: left;
        margin-top: 0%;
        margin-bottom: 0%;
    }

    .about-text {
        font-size: 32px;
        line-height: 38px;
    }
    
}


@media screen and (max-width: 480px) {


    .about {
    }
    

    .about-text {
        margin-top: 8%;
        font-size: 18px;
        line-height: 28px;
    }
    
}