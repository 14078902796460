.embeded-form-wrap {
  width: 100%;

}


.embeded-form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;

}

.validate {
  width: 60%;
}

.clear-foot {
  padding: 0;
}

.form-elements-wrap{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  flex-direction: row;



}

.newsletter-label {
  font-size: 1vw;
  font-weight: 500;
}

.input-wrap {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 35%;

}




.required-email{
  
  border: none;
  background-color: rgb(228, 228, 228);
  border-radius: 5px;
  width: 75%;
  font-size: 0.9vw;
  padding-left: 1vw;
  margin-left: 1.5vw;
  border: 1px solid rgb(199, 199, 199);
}

.subscribe-button {
  height: 100%;
  width: 25%;
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 10px 10px 10px;
  margin-left: 0.5vw;
  font-size: 1vw;
}

.subscribe-button:hover {
  cursor: pointer;
  background-color: rgb(60, 60, 60);
 
}


#COLLAPSED {
  transition: 0.2S;
  width: 0;
  opacity: 0;
}

#DEFAULT-2 {
  transition: 0.2S;
  font-size: 1.25vw;
  font-weight: 600;

}

#SUCCESS-2 {
  transition: 0.2S;
  font-size: 1.25vw;
  font-weight: 600;

}

#ERROR-2 {
  transition: 0.2S;
  font-size: 1.25vw;
  font-weight: 600;
  color: red;

}

@media screen and (max-width: 1280px) {

  .validate {
    width: 60%;
  }
  

  .newsletter-label {
    font-size: 1.5vw;
    font-weight: 500;
  }
  
  .input-wrap {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 50%;
  
  }
  
  
  
  
  .required-email{
    border: none;
    background-color: rgb(228, 228, 228);
    border-radius: 5px;
    width: 75%;
    font-size: 1.5vw;
    padding-left: 1vw;
    margin-left: 1.5vw;
  }
  
  .subscribe-button {
    width: 25%;
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 10px 10px 10px;
    margin-left: 0.5vw;
    font-size: 2vw;
  }
  

  #DEFAULT-2 {
    font-size: 1.75vw;
    font-weight: 600;
  
  }
  
  #SUCCESS-2 {
    font-size: 1.75vw;
    font-weight: 600;
  
  }
  
  #ERROR-2 {
    font-size: 1.75vw;
    font-weight: 600;
    color: red;
  
  }

 

}


@media screen and (max-width: 840px) {

  .footer-form-wrap {
    height: 15vh;
 
  
  }

  .form-elements-wrap{
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: end;
    justify-content: right;
    flex-direction: column;
    
  
  
  }  
  

  .validate {
    width: 100%;
  }

  .newsletter-label {
    font-size: 4vw;
    font-weight: 500;
  }

  .input-wrap {
    width: 80%;
    height: 50px;
    margin-top: 2vh;
  
  }


  .required-email{
    border: none;
    background-color: rgb(228, 228, 228);
    border-radius: 5px;
    width: 75%;
    font-size: 3vw;
    padding-left: 2vw;
    margin-left: 0vw;
  }
  
  .subscribe-button {
    width: 25%;
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 10px 10px 10px;
    margin-left: 2vw;
    font-size: 3vw;
  }

  #DEFAULT-2 {
    font-size: 3vw;
    font-weight: 600;
  
  }
  
  #SUCCESS-2 {
    font-size: 3vw;
    font-weight: 600;
  
  }
  
  #ERROR-2 {
    font-size: 3vw;
    font-weight: 600;
    color: red;
  
  }

  

  
  



}


@media screen and (max-width: 480px) {

  .footer-form-wrap {
    height: 15vh;
 
  
  }

  .form-elements-wrap{
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: end;
    justify-content: right;
    flex-direction: column;
    
  
  
  }  
  

  .validate {
    width: 100%;
  }

  .newsletter-label {
    font-size: 5vw;
    font-weight: 500;
  }

  .input-wrap {
    width: 100%;
    height: 50px;
    margin-top: 2vh;
  
  }


  .required-email{
    border: none;
    background-color: rgb(228, 228, 228);
    border-radius: 5px;
    width: 75%;
    font-size: 4vw;
    padding-left: 4vw;
    margin-left: 0vw;
  }
  
  .subscribe-button {
    width: 25%;
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 10px 10px 10px;
    margin-left: 2vw;
    font-size: 5vw;
  }

 

  #DEFAULT-2 {
    font-size: 5vw;
    font-weight: 600;
  
  }
  
  #SUCCESS-2 {
    font-size: 5vw;
    font-weight: 600;
  
  }
  
  #ERROR-2 {
    font-size: 5vw;
    font-weight: 600;
    color: red;
  
  }
  
  
  
  
  



}



  
  

