.presentation {
    position: static;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: -100px;
}


.section {
    color: white;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: left;
    min-height: 90vh;
    margin-bottom: 5vh;
}

h1 {
    font-size: 7vw;
    margin: 0;
}

p {
    font-weight: 200;
    font-size: 1.25vw;
}



.paragraph-wrap {
  margin: 0;
}
.presentation-icon-wrap {
    width: 100%;
    display: flex;

}

.presentation-svg {
    width: 25%;
    mix-blend-mode: difference;
    animation: animate 10s linear infinite;
  }
  @keyframes animate {
    0% {
     transform: scaleY(1) scaleX(1);
    }

    25% {
      transform:  scaleY(1.05) scaleX(1);}

    50% {
        transform:  scaleX(1.01) scaleY(1);}
   
    75% {
        transform:  scaleY(1.05) scaleX(1) ;}


    100% {
      transform: scaleY(1) scaleX(1);}
}



/*MEDIUM SCEEEN MEDIA QUARIES*/

@media screen and (max-width: 1280px) {

    .section {
        color: white;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        text-align: left;
        min-height: 90vh;
        margin-bottom: 0vh;
    }
    
    h1 {
        font-size: 7vw;
        margin: 0;
    }

    #slogan {
        font-size: 10vw;
        
    }
    
    p {
        font-weight: 200;
        font-size: 1.6vw;
    }
    
    
    
    .paragraph-wrap {
      margin: 0;
    }
    .presentation-icon-wrap {
        width: 100%;
        display: flex;
    
    }

    .presentation-svg {
        width: 25%;
        mix-blend-mode: difference;
        animation: animate 10s linear infinite;
      }
      @keyframes animate {
        0% {
         transform: scaleY(1) scaleX(1);
        }
    
        25% {
          transform:  scaleY(1.05) scaleX(1);}
    
        50% {
            transform:  scaleX(1.01) scaleY(1);}
       
        75% {
            transform:  scaleY(1.05) scaleX(1) ;}
    
    
        100% {
          transform: scaleY(1) scaleX(1);}
    }
    


  }





@media screen and (max-width: 884px) {
    .section {
        margin-top: 5%;
    }

    #first {
        margin-top: 10%;
    }

    h1 {
        font-size: 90px;
        margin: 0;
    }

    #slogan {
        font-size: 120px;
    }
    
    p {
        font-weight: 200;
        font-size: 3.5vw;
    }

    .section {

        min-height: 60vh;
        margin-bottom: 0%;
    }

    .presentation-svg {
        width: 35%;
    
      }

  }


 

  
@media screen and (max-width: 480px) {
    .section {
        margin-top: 5%;
    }

    #first {
        margin-top: 25%;
    }

    h1 {
        font-size: 55px;
        margin: 0;
    }

    #slogan {
        font-size: 55px;
    }
    
    p {
        font-weight: 200;
        font-size: 6vw;
    }

    .section {

        min-height: 60vh;
        margin-bottom: 20%;
        
    }

    .presentation-icon-wrap {
        
    margin-bottom: 5%;
    }

    .presentation-svg {
        width: 75%;
        
    
      }

  }

