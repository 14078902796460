
.nav-bar {
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    height: 75px;
    position: fixed;
    transform: translateY(-4px);
    top: 0;
    transition: 0.2s;
    z-index: 99;
}


.nav-bar-hidden {
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    height: 75px;
    position: fixed;
    top:-90px;
    transition: 0.2s;
}

.nav-bar-elements {
    width: 65%;
    height: 100%;
    margin: 4px auto 0px auto;
    display: flex;
   
}

img {
    width: 100%;
}


/* LEFT SIDE OF NAVBAR */

.nav-bar-left{
    width: 100%;
   
}


.logo-wrap{
    height: 100%;
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: left;
}

.realbeat-logo {
    height: 55%;
    width:auto;
    transition: 0.2s;
}

.realbeat-logo:hover{
    cursor: pointer;
    transform: scale(1.1);
}



/* RIGHT SIDE OF NAVBAR */


.nav-bar-right{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: right;
   

    
}

.nav-bar-menu-wrap {
    height: 100%;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: right;


}

@media screen and (max-width: 1280px) {

    .nav-bar-elements {
        width: 80%;
 
    }

}




@media screen and (max-width: 840px) {

    .nav-bar-elements {
        width: 90%;
 
    }


    .nav-bar-left {
     
        width: 50%;
    }

    
    
    
  
  }



  
@media screen  and (max-width: 480px) {

    .nav-bar {
        background-color: rgba(255, 255, 255, 1);
        width: 100%;
        height: 60px;
        position: fixed;
        top: 0px;
        transition: 0.2s;
        z-index: 99;
    }
    

    .nav-bar-elements {
        width: 90%;

 
    }
    
    
  
  }
  
  