.social-media-icon {
    width: 3.5vw;
    height: auto;
    margin-left: 2vw;
    transition: 0.2s;
}

.social-media-icon:hover {
    cursor: pointer;
    transform: scale(1.1);
}


@media screen and (max-width: 1280px) {

    .social-media-icon {
        width: 6vw;
        height: auto;
        margin-left: 3vh;
        transition: 0.2s;
    }
    

}


@media screen and (max-width: 884px) {

    .social-media-icon {
        width: 8vw;
        height: auto;
        margin-left: 15px;
        transition: 0.2s;
    }
    

}



@media screen and (max-width: 480px) {

    .social-media-icon {
        width: 50px;
        height: auto;
        margin-left: 15px;
        transition: 0.2s;
    }
    

}


@media screen and (max-width: 390px) {

    .social-media-icon {
        width: 14vw;
        height: auto;
        margin-left: 4vw;
        transition: 0.2s;
    }
    

}