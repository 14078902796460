body {
  margin: 0;
  font-family: 'Marck Script', cursive;
  font-family: 'Montserrat', sans-serif;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;
}




/*Scrollbar Style*/

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color:  rgba(0, 0, 0, 1);  
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background:rgb(255, 255, 255);
  border-radius: 10px;
  transition: 0.3s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.8);
}