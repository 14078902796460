
.extended-form-container {
  background-color: rgb(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: 1%;
  align-content: center;
  transition: all 0.3S ease-in;
  height: auto;
  }

  .extended-form {
    width: 100%;
    height: auto;
  }

  .close-icon-wrap {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .close-icon {
    filter: invert();
    width: 6.5%;
    height: auto;
  }

  .info-field {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    ;

  }
  
  img {
      width: 100%;
  }
  
  .form-icon-wrap {
      width: 100%;
      margin-bottom: 0%;
  }


  .input-field-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10%;
    padding-top: 5%;
    margin: 0;
  }

  .email-field {
    width: 65%;
    height: 40px;
    border: 1px solid rgb(197, 197, 197);
    border-radius: 5px;
    padding-left: 2%;
    background-color: #d3d3d3;
  }

  .join-button {
    border-radius: 5px;
    margin-top: 2%;
    width: 68%;
    height: 50px;
    background-color: #000000;
    border: 1px solid black;
    color: white;
    padding: 0;
  }

  .join-button:hover {
    background-color: #3d3d3d;
    cursor: pointer;
  }

  .form-message {
    margin-bottom: 8%;
    font-weight: 600;
  }

  #SENDING {
    font-size: 2vh;
  }

  #ERROR {
    color: #f03d3d ;
    transition: 0.1s;
  }

  #SUCCESS {
    transition: 0.1s;
    font-size: 4vh;
  }

  #VISIBLE {
    transition: 0.1s;
    transition: 0.1s;
  }

  #HIDDEN {
    transition:0.1s;
    display: none;
  }
  
  .form-icon {
      transition: 0.1s;
      width: 80%;
      object-fit: scale-down;
      animation: animateIcon 10s linear infinite;
  }
  @keyframes animateIcon {
    0% {
     transform: scaleY(1) scaleX(1);
    }

    25% {
      transform:  scaleY(1.05) scaleX(1);}

    50% {
        transform:  scaleX(1.04) scaleY(1);}
   
    75% {
        transform:  scaleY(1.05) scaleX(1) ;}


    100% {
      transform: scaleY(1) scaleX(1);}
}


#sending-img {
  transition: 0.1s;
  width: 50%;
  padding: 15%;
  object-fit: scale-down;
  animation: rotate 2s linear infinite;
  margin-bottom: 10%;
}
@keyframes rotate{
0% {
 transform: rotate(0deg);
}

100% {
  transform: rotate(-360deg);}
}


img {
  width: 100%;

}

  
  #label-default {
    width: 100%;
    font-size:28px;
    text-align: center;
    font-weight: 800;
    color: black;
    margin-bottom: 0px;
    transition: all 0.3s;
  }

  
  #label-ok {
    width: 100%;
    font-size:28px;
    text-align: center;
    font-weight: 800;
    color: #3fc561;
    margin-bottom: 0px;
    transition: all 0.3s;
    padding-bottom: 2%;
  }

  #label-Error {
    width: 100%;
    font-size:24px;
    text-align: center;
    font-weight: 800;
    color: #f03d3d;
    margin-bottom: 0px;
    transition: all 0.3s;
    padding-bottom: 2%;
  }

  

  .form-text {
    margin-top: 5px;
    font-size: 15px;
    font-weight: 400;
    color: #000000;
  
  
  }

  .form-text-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 15.5px;
    margin-top: 15px;
    padding: 0;
  }




  

  @media screen and (max-width: 1280px) {

    .extended-form-container {
      transform: scale(0.8);
      padding: 2%;
  
    }}

  
  


  @media screen and (max-width: 840px), (-webkit-min-device-pixel-ratio: 2) {

    .extended-form-container {
      transform: scale(1);
      padding: 3%;
   
    }


    .email-field {

      font-size: 2vw;;
   
    }
  
    .join-button {
      font-size: 2vw;
    
    }
  
  
  
  
  }
    

      
      


  
  
  @media screen and (max-width: 480px) {

 

      .input-field-wrap {
      
        padding-bottom: 10%;
        padding-top: 5%;
        margin: 0;
      }

      .info-field {
        margin-top: 4%;
      }
    
    
      .form-icon {
        transition: 0.1s;
        width: 50%;
        object-fit: scale-down;
        animation: animateIcon 10s linear infinite;
    }
    @keyframes animateIcon {
      0% {
       transform: scaleY(1) scaleX(1);
      }
  
      25% {
        transform:  scaleY(1.05) scaleX(1);}
  
      50% {
          transform:  scaleX(1.04) scaleY(1);}
     
      75% {
          transform:  scaleY(1.05) scaleX(1) ;}
  
  
      100% {
        transform: scaleY(1) scaleX(1);}
  }
  

    .email-field {
      width: 85%;

    }
  
    .join-button {

      width: 88%;
     
    }

    .extended-form-container {
      transform: scale(0.85);
      padding: 4%;
      
  
    }

    .email-field {

      font-size: 4vw;;
   
    }
  
    .join-button {
      font-size: 4vw;
    
    }
  
  

    
  
    
      
  }




  @media screen and (max-width: 320px) {

    #label-default {

      font-size:22px;
   
    }
  
    
    #label-ok {

      font-size:22px;
   
    }
  
    #label-Error {
  
      font-size:22px;
     
    }
    
    }

    @media screen and (max-height: 420px) {

      .extended-form-container {
    
    
      }

   
      .form-icon {
        display: none;
      }

      #label-default {

        font-size:22px;
     
      }
    
      
      #label-ok {
  
        font-size:22px;
     
      }
    
      #label-Error {
    
        font-size:22px;
       
      }
      
      }
        

    @media screen and (max-height: 320px) {

      .extended-form-container {
    
    
      }

      .info-field {
        display: none;
      }

      .form-icon {
        display: none;
      }

      #label-default {

        font-size:18px;
     
      }
    
      
      #label-ok {
  
        font-size:18px;
     
      }
    
      #label-Error {
    
        font-size:18px;
       
      }
      
      }
        
      
  