.newsletter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;
    background-color:  rgb(250, 245, 234, 0.9);
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 10;
 
}




.newsletter.open {

    background-color: rgba(250, 234, 242, 0.8);
    transform: translateY(0%);
    transition: transform 0.3s ease-out;
}


