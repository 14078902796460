.people-wrap {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    z-index: 1;
    margin-top: 0 ;
    margin-left: auto;
    margin-right: auto;
    justify-items: center;
    
}


/*People Box Component cotaining photo and info */

.people-box {
    min-width: 90%;
    display: grid;
    grid-template-rows:  55% 45%;
    background-color: rgb(255, 255, 255, 0.05);
    border-radius: 10px;
    border: 1px solid rgb(255, 255, 255, 0.1);
    align-content: center;
    align-items: center;
    overflow: hidden ;
    margin: 5%;
    
    
}

img {
    width: 100%;
    overflow:hidden;
}

.people-photo {
    object-fit:cover;
    overflow: hidden;
    justify-self: center;

    
}


.people-info {
    display: grid;
    grid-template-rows: 20% 80% ;
    align-items:start;
    margin: 4%;
    padding-top: 60px;
    height: 100%;
}



.people-name-wrap {
    color: rgb(255, 255, 255);
    text-align: right;
  
}

.people-name {
    font-size: 32px;
    margin: 0;


   
}

h6, .people-title {
    margin-top: 0;
    margin-bottom: 0px;

    font-weight: 300;
    font-size: 18px;

}

.people-bio {
    color: white;
    font-weight: 300;
 
    margin: 0;
    font-size: 18px;
    text-align: left;
    margin-bottom: 0px;
    margin-top: 20px;
}

.people-contact {
    margin-top: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}




.people-contact-details {
    color: white;
    font-size: 22px;
    margin: 0;
    text-align: left;
    font-weight: 600;


   
}

@media screen and (max-width: 1280px) {

    .people-wrap {
       
       
     
    }
    
    .people-box {
       
        grid-template-rows:  50% 50%;
        margin: 5%;

        
        
        
    }


    }

    




@media screen and (max-width: 884px) {

    .people-wrap {
        grid-template-columns: 100%;
        row-gap: 5%;

     
    }
    
    .people-box {
        max-width: 65%;
        height: fit-content;
        
        
        
    }

    .people-bio {
        margin-top: 0px;
        font-size: 20px;
        
    
       
    }


    }

    
    




@media screen and (max-width: 480px) {

    .people-wrap {
        grid-template-columns: 100%;
     
    }
    
    .people-box {
        width: 90%;
    
        grid-template-rows:  35% 65%;
        margin: 0%;
    

   
        
    }


    .people-info {
        display: grid;
        grid-template-rows: 20% 80% ;
        align-items:start;
        margin: 20px;
        padding-top: 75px;
        height: 100%;
    }
    

    .people-bio {
        margin-top: 0px;
        font-size: 15.5px;
        
    
       
    }
        

}




@media screen and (max-width: 390px) {

    .people-wrap {
        grid-template-columns: 100%;
     
    }
    
    .people-box {
       width: 95%;
        grid-template-rows:  35% 65%;
    

   
        
    }


    .people-info {
        display: grid;
        grid-template-rows: 20% 80% ;
        align-items:start;
        margin: 20px;
        padding-top: 75px;
        height: 100%;
    }
    

    .people-bio {
        margin-top: 0px;
        font-size: 14px;
        margin-bottom: 0px;
        padding-bottom: 0;
        
    
       
    }
        

}